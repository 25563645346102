.slide-container {
    display: flex;
    flex-direction: column;
}

.slide-images {
    display: flex;
    overflow: scroll;
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 20px;
    img {
        max-width: 100px;
        min-width: 100px;
        margin: 0 10px;
    }
    img:first-of-type {
        margin-left: 0px;
    }
    img:last-of-type {
        margin-right: 0px;
    }

}

@media screen and (min-width: 1000px) {
    .main-image {
        min-width: 600px;
        max-width: 700px;
    }
    .slide-images {
        max-width: 480px;
    }
}