.account-container {
  margin: 50px 0;
  min-height: calc(95vh - 40px - 364px);
}

.greeting {
  font-size: 1.5rem;
}

.account-navigation {
  display: flex;
  justify-content: center;
  text-decoration: none;
  list-style: none;
  text-align: center;
  li {
    padding: 0 15px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: var(--primary);
    }
  }
}

.account-orders {
  margin: 30px 0;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  h2 {
    font-size: 2rem;
  }
}

.order-container {
  width: 150px;
  position: relative;
  p {
    font-size: 1.3rem;
    margin-top: 100px;
    color: rgb(167, 167, 167);
  }
}

.order-notification {
  position: absolute;
  border-radius: 10px;
  background-color: var(--off-black);
  color: white;
  width: 20px;
  height: 20px;
  right: 1%;
}

.order-components-container {
  @include flex();
  justify-content: center;
}

.orders {
  @include flex();
  justify-content: center;
  p:nth-of-type(1) {
    margin-right: 10px;
  }
}

.order-details {
  @include flex();
  justify-content: space-between;
  margin: 20px 0;
}

.date,
.payment,
.city,
.state,
.zip,
.total {
  p:first-of-type {
    font-size: 0.7rem;
    color: rgba(99, 99, 99, 0.815);
  }
  width: 80px;
}

.address {
  p:first-of-type {
    font-size: 0.7rem;
    color: rgba(99, 99, 99, 0.815);
  }
  width: auto;
}

.order-button {
  button {
    height: 40px;
    width: 200px;
  }
  p {
    font-size: 1rem;
  }
}

.orders-components-mobile {
  width: 200px;
  padding: 20px;
  border: 1px solid var(--border);
  border-radius: 10px;
}

.orders-wrapper {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-template-rows: repeat(auto, 300px);
  gap: 60px;
  margin-inline: auto;
  width: min(90%, 60rem);
  margin-top: 10px;
}

.addresses-wrapper {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-template-rows: repeat(auto, 200px);
  gap: 60px;
  margin-inline: auto;
  width: min(90%, 50rem);
  margin-top: 10px;
}

.add-address {
  @include flex();
  justify-content: center;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: var(--primary);
  }
}

.address-details-container {
  width: 200px;
  padding: 20px;
  border: 1px solid var(--border);
  border-radius: 10px;
}

.address-details {
  p {
    padding: 5px 0;
  }
}

.crud {
  button {
    background-color: var(--primary);
    color: white;
    border-radius: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    width: 70px;
    transition: all 0.3s ease-in-out;
    &:nth-of-type(2) {
      margin-left: 5px;
    }
    &:hover {
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 0.329);
      color: black;
    }
  }
}

@media screen and (min-width: 1000px) {
}
