@mixin flex {
  display: flex;
  align-items: center;
}

.products-container {
  display: flex;
  flex-direction: column;
}

.collection {
  margin-bottom: 70px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  width: min(90%, 60rem);
}

.collection-image {
  img {
    min-width: 320px;
    max-width: 500px;
    border: solid black 1px;
    animation: fade ease-in-out 1s;
  }
  display: flex;
  justify-content: center;
}

.collection-text {
  animation: slideUp ease-in-out 1s;
  h2 {
    font-weight: 300;
    font-size: 1.5rem;
  }
  p {
    padding: 5px 0;
    font-weight: 100;
  }
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.shopNow {
  display: none;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  animation: slideUp ease-in-out 2s;
  p {
    font-style: italic;
    font-size: 1.2rem;
    font-weight: 100;
  }
}

.scroll {
  height: 60px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 30px 0;
  gap: 50px;
  margin-inline: auto;
  width: min(90%, 80rem);
}

.product {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  transition: all ease-in-out 0.2s;
  img {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
}

.product-image {
  @include flex();
  justify-content: center;
}

.product-text {
  margin-top: 5px;
  p {
    padding: 3px;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 1000px) {
  .products-container {
    justify-content: space-between;
  }

  .collection {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: auto;
    width: min(90%, 80rem);
    min-height: 75.4vh;
    flex-direction: row-reverse;
  }
  .collection-image {
    display: block;
    img {
      width: 60%;
      max-width: none;
      min-width: none;
    }
  }

  .shopNow {
    display: flex;
    p {
      animation-delay: 3s;
      animation: fade ease-in-out 2s;
    }
    div {
      animation: slideUp ease-in-out 2s;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        transform: translateY(8px);
      }
    }
  }

  .collection-text {
    h2 {
      font-size: 3rem;
    }
    p {
      font-size: 2rem;
    }
  }
}

@media screen and (min-width: 740px) and (max-width: 999px) {
  .collection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }

  .collection-text {
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1.5rem;
    }
  }

  .shopNow {
    display: flex;
  }

  .collection-image {
    img {
      max-width: 400px;
    }
  }
}

// Main individual product page

.individual-container {
  img {
    width: 100%;
    border-radius: 10px;
  }
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  min-height: calc(95vh - 40px - 364px);
}

.title {
  @include flex();
  margin: 20px 0;
  justify-content: space-between;
  p:first-of-type {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.3rem;
  }
}

.reviews {
  @include flex();
  margin: 20px 0;
  p:last-of-type {
    font-weight: 300;
    text-decoration: underline;
    margin-left: 10px;
  }
}

.color-container {
  border-top: rgb(187, 187, 187) 1px solid;
  border-bottom: rgb(187, 187, 187) 1px solid;
  padding: 20px 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 20px;
  }
}

.color-picker {
  @include flex();
  p {
    font-weight: 300;
    margin-top: 5px;
  }
}

.colors {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.details {
  @include flex();
  padding: 10px 0;
  p {
    margin-left: 10px;
  }
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  button {
    width: 300px;
    height: 50px;
    background-color: #92a9bd;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #92a9bd;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.291);
    }
  }
}

.promise {
  @include flex();
  justify-content: space-between;
}

@media screen and (min-width: 760px) {
  .individual-container {
    display: flex;
    margin: 100px 0;
    flex-direction: row;
    justify-content: space-between;
    margin-inline: auto;
    width: min(90%, 70rem);
    img {
      height: auto;
      width: 80%;
    }
  }
  .title {
    margin-top: 0px;
  }
}

@media screen and (min-width: 550px) and (max-width: 759px) {
  .individual-container {
    margin-inline: auto;
    width: min(70%, 80rem);
  }
}
