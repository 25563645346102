:root {
  --primary: #92a9bd;
  --border: rgba(0, 0, 0, 0.24);
  --off-black: rgb(51, 51, 51);
}

html {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Albert Sans", sans-serif;
}

.logo {
  display: flex;
  align-items: center;
}
.logo a {
  text-decoration: none;
  color: black;
}
.logo h2 {
  font-size: 3rem;
}
.logo img {
  height: 80px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px;
}
header h2 {
  font-size: 1.7rem;
  cursor: pointer;
}

.nav {
  display: none;
  cursor: pointer;
  position: relative;
}

.cart-num {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  border: 1px solid;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  font-size: 0.9rem;
  background-color: #92a9bd;
  color: white;
  bottom: 1%;
  right: 1%;
}

.banner {
  background-color: rgb(51, 51, 51);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  position: relative;
  cursor: pointer;
}
.banner p {
  -webkit-animation: slideUpBanner 0.3s ease-in-out;
          animation: slideUpBanner 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.banner p:nth-of-type(2) {
  display: none;
  -webkit-animation: slideUp 0.3s ease-in-out;
          animation: slideUp 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.banner img:first-of-type {
  height: 25px;
  position: absolute;
  left: 1%;
}
.banner img:last-of-type {
  position: absolute;
  height: 25px;
  right: 1%;
}

@media screen and (min-width: 760px) {
  .nav {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
  }
  li {
    padding: 0 10px;
  }
  a {
    position: relative;
    text-decoration: none;
    color: black;
  }
  a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: #92a9bd;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
  .mobile-menu {
    display: none;
  }
  header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
  }
  header h2 {
    font-size: 2rem;
  }
  .banner img:first-of-type {
    left: 20%;
  }
  .banner img:last-of-type {
    right: 20%;
  }
}
@media screen and (min-width: 1300px) {
  .banner img:first-of-type {
    left: 30%;
  }
  .banner img:last-of-type {
    right: 30%;
  }
  .logo {
    margin: 0px;
    display: flex;
    align-items: center;
  }
}
@-webkit-keyframes slideUpBanner {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slideUpBanner {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.products-container {
  display: flex;
  flex-direction: column;
}

.collection {
  margin-bottom: 70px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  width: min(90%, 60rem);
}

.collection-image {
  display: flex;
  justify-content: center;
}
.collection-image img {
  min-width: 320px;
  max-width: 500px;
  border: solid black 1px;
  -webkit-animation: fade ease-in-out 1s;
          animation: fade ease-in-out 1s;
}

.collection-text {
  -webkit-animation: slideUp ease-in-out 1s;
          animation: slideUp ease-in-out 1s;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.collection-text h2 {
  font-weight: 300;
  font-size: 1.5rem;
}
.collection-text p {
  padding: 5px 0;
  font-weight: 100;
}

.shopNow {
  display: none;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  -webkit-animation: slideUp ease-in-out 2s;
          animation: slideUp ease-in-out 2s;
}
.shopNow p {
  font-style: italic;
  font-size: 1.2rem;
  font-weight: 100;
}

.scroll {
  height: 60px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 30px 0;
  gap: 50px;
  margin-inline: auto;
  width: min(90%, 80rem);
}

.product {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  transition: all ease-in-out 0.2s;
}
.product img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.product:hover {
  opacity: 0.8;
}

.product-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-text {
  margin-top: 5px;
}
.product-text p {
  padding: 3px;
}

@-webkit-keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (min-width: 1000px) {
  .products-container {
    justify-content: space-between;
  }
  .collection {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: auto;
    width: min(90%, 80rem);
    min-height: 75.4vh;
    flex-direction: row-reverse;
  }
  .collection-image {
    display: block;
  }
  .collection-image img {
    width: 60%;
    max-width: none;
    min-width: none;
  }
  .shopNow {
    display: flex;
  }
  .shopNow p {
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    -webkit-animation: fade ease-in-out 2s;
            animation: fade ease-in-out 2s;
  }
  .shopNow div {
    -webkit-animation: slideUp ease-in-out 2s;
            animation: slideUp ease-in-out 2s;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .shopNow div:hover {
    transform: translateY(8px);
  }
  .collection-text h2 {
    font-size: 3rem;
  }
  .collection-text p {
    font-size: 2rem;
  }
}
@media screen and (min-width: 740px) and (max-width: 999px) {
  .collection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
  .collection-text h2 {
    font-size: 2rem;
  }
  .collection-text p {
    font-size: 1.5rem;
  }
  .shopNow {
    display: flex;
  }
  .collection-image img {
    max-width: 400px;
  }
}
.individual-container {
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  min-height: calc(95vh - 40px - 364px);
}
.individual-container img {
  width: 100%;
  border-radius: 10px;
}

.title {
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: space-between;
}
.title p:first-of-type {
  font-size: 1.5rem;
}
.title p {
  font-size: 1.3rem;
}

.reviews {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.reviews p:last-of-type {
  font-weight: 300;
  text-decoration: underline;
  margin-left: 10px;
}

.color-container {
  border-top: rgb(187, 187, 187) 1px solid;
  border-bottom: rgb(187, 187, 187) 1px solid;
  padding: 20px 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.color-container h3 {
  margin-bottom: 20px;
}

.color-picker {
  display: flex;
  align-items: center;
}
.color-picker p {
  font-weight: 300;
  margin-top: 5px;
}

.colors {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.details {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.details p {
  margin-left: 10px;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.button button {
  width: 300px;
  height: 50px;
  background-color: #92a9bd;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.button button:hover {
  color: #92a9bd;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.291);
}

.promise {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 760px) {
  .individual-container {
    display: flex;
    margin: 100px 0;
    flex-direction: row;
    justify-content: space-between;
    margin-inline: auto;
    width: min(90%, 70rem);
  }
  .individual-container img {
    height: auto;
    width: 80%;
  }
  .title {
    margin-top: 0px;
  }
}
@media screen and (min-width: 550px) and (max-width: 759px) {
  .individual-container {
    margin-inline: auto;
    width: min(70%, 80rem);
  }
}
#White {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
}

#Black {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: black;
}

#Blue {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(150, 200, 221);
}

#Forest {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(115, 147, 117);
}

#Aqua {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(150, 200, 221);
}

#Snow {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
}

#Ash {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgba(239, 215, 171, 0.507);
}

#Walnut {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(83, 63, 36);
}

#Okuome {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(188, 139, 116);
}

#Redwood {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(121, 42, 41);
}

#Maple {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgba(239, 215, 171, 0.507);
}

.selected {
  border: black 2px solid;
}

.notSelected {
  border: rgb(165, 165, 165) 2px solid;
}

.side-bar-nav {
  display: flex;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.908);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  transition: all ease-in 0.3s;
}
.side-bar-nav li {
  padding: 10px 0;
  font-size: 1.5rem;
}
.side-bar-nav a {
  color: black;
}

.close {
  position: absolute;
  top: 4.2%;
  right: 10.5%;
  font-size: 1.5rem;
  cursor: pointer;
}

.about-container {
  margin-inline: auto;
  width: min(90%, 80rem);
  margin-bottom: 100px;
}

.about-cover {
  position: relative;
  display: flex;
  justify-content: center;
}
.about-cover img {
  width: 100%;
  border: 1px black solid;
}

.image-text span::before {
  content: "";
  opacity: 0;
}

.image-text {
  font-size: 8.5vw;
  text-align: center;
  position: absolute;
  top: 5%;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.404);
}
.image-text span:first-of-type {
  -webkit-animation: fade 0.5s ease-in-out;
          animation: fade 0.5s ease-in-out;
}
.image-text span:nth-of-type(2) {
  -webkit-animation: fade 1s ease-in-out;
          animation: fade 1s ease-in-out;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
}
.image-text span:nth-of-type(3) {
  -webkit-animation: fade 1s ease-in-out;
          animation: fade 1s ease-in-out;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
}
.image-text span:nth-of-type(4) {
  -webkit-animation: fade 1s ease-in-out;
          animation: fade 1s ease-in-out;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
}

.arrow {
  margin-top: 6px;
  cursor: pointer;
  -webkit-animation: slideUp 1s ease-in-out;
          animation: slideUp 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.arrow:hover {
  padding: 6px;
}

.about-description {
  margin: 40px 0;
  border-bottom: 1px rgba(0, 0, 0, 0.24) solid;
}
.about-description p {
  margin-top: 10px;
  font-size: 1.2rem;
  line-height: 160%;
  padding: 30px 20px;
  border-top: 1px rgba(0, 0, 0, 0.24) solid;
}

.our-promise {
  margin-top: 20px;
}
.our-promise img {
  width: 100%;
  border: black 1px solid;
}

.contact {
  margin-bottom: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.291);
  border-top: 1px solid rgba(0, 0, 0, 0.291);
}
.contact p {
  padding: 5px 30px;
}

.input-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.input-field input {
  height: 20px;
  width: auto;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.291);
  padding: 8px;
}
.input-field input::-moz-placeholder {
  opacity: 0.2;
}
.input-field input:-ms-input-placeholder {
  opacity: 0.2;
}
.input-field input::placeholder {
  opacity: 0.2;
}
.input-field input:focus {
  border: black 1px solid;
  outline: none;
}

.about-button button {
  background-color: #92a9bd;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.about-button button:hover {
  color: #92a9bd;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.291);
}

@media screen and (min-width: 1000px) {
  .about-container {
    width: min(90%, 50rem);
  }
  .image-text {
    font-size: 4rem;
    padding: 0 90px;
  }
  .about-description p {
    padding: 50px 100px;
    line-height: 200%;
  }
  .about-cover img {
    height: auto;
    width: 85%;
  }
  .contact p {
    margin-top: 10px;
  }
}
.test-cart {
  display: flex;
  justify-content: flex-end;
  transition: visibility 0.5s ease-in-out;
}

.cart-body {
  background-color: white;
  height: 100vh;
  width: 350px;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
}

.overlay {
  width: 100vw;
  height: 300vh;
  background-color: rgba(0, 0, 0, 0.435);
  position: absolute;
  z-index: 99;
  transition: opacity 0.5s, visibility 0s ease-in-out;
}

.cart-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.291);
}
.cart-top button {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}
.cart-top p {
  margin-left: 10px;
}

.bag {
  display: flex;
  align-items: center;
}

.shipping-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.border {
  border: 1px black solid;
  height: 7px;
  border-radius: 10px;
  margin-top: 10px;
}

.bar {
  background-color: black;
  height: 7px;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.item-full {
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: all 0.7s ease-in-out;
  position: relative;
  margin-top: 20px;
}

.item-container {
  padding: 10px;
}

.cart-item-container {
  display: flex;
  align-items: center;
}

.cart-item-container-text {
  width: 150px;
}

.cart-image {
  display: flex;
  align-items: center;
}
.cart-image img {
  max-width: 80px;
  height: auto;
  border-radius: 10px;
  margin-right: 15px;
}

.add-sub {
  border: 1px solid black;
}
.add-sub input {
  border: none;
  width: 10px;
}
.add-sub input:focus {
  outline: none;
}

.quantity {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 50px);
}
.quantity button {
  width: 30px;
  height: 30px;
  background-color: #e1e8ee;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quantity img {
  width: auto;
  height: 60%;
}

.quantity input {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 32px;
  font-size: 16px;
  color: #43484d;
  font-weight: 300;
}

button:focus,
input:focus {
  outline: 0;
}

.subtotal {
  display: flex;
  justify-content: space-between;
}

.checkout {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.291);
  bottom: 0;
}
.checkout button {
  background-color: black;
  color: white;
  margin-top: 10px;
  border: none;
  border-radius: 20px;
  height: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.checkout button:disabled {
  background-color: rgba(166, 166, 166, 0.63);
  color: rgb(255, 255, 255);
}

.cart-empty {
  margin-top: 50px;
}

.test {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cart-components {
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

@media screen and (min-width: 1000px) {
  .cart-body {
    width: 550px;
  }
  .cart-item-container-text {
    width: 200px;
  }
  .quantity {
    width: calc(100% - 100px);
  }
}
.slide-container {
  display: flex;
  flex-direction: column;
}

.slide-images {
  display: flex;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 20px;
}
.slide-images img {
  max-width: 100px;
  min-width: 100px;
  margin: 0 10px;
}
.slide-images img:first-of-type {
  margin-left: 0px;
}
.slide-images img:last-of-type {
  margin-right: 0px;
}

@media screen and (min-width: 1000px) {
  .main-image {
    min-width: 600px;
    max-width: 700px;
  }
  .slide-images {
    max-width: 480px;
  }
}
.footer-container-2 {
  background-color: #e3ebf4;
  border-top: 1px solid rgba(0, 0, 0, 0.24);
}

.footer-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-inline: auto;
  width: min(90%, 80rem);
  background-color: #e3ebf4;
}
.footer-container h2 {
  font-size: 2rem;
}

.subscriber-input {
  position: relative;
}

.subscriber {
  width: 300px;
}
.subscriber p {
  padding: 10px 0;
}
.subscriber input {
  width: calc(100% - 20px);
  height: 25px;
  padding: 10px 10px;
  border-radius: 25px;
  border: solid rgb(133, 133, 133) 1px;
  margin-top: 20px;
}
.subscriber button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 45%;
  cursor: pointer;
}

.explore,
.support {
  margin-top: 30px;
}
.explore p:first-of-type,
.support p:first-of-type {
  color: rgba(45, 45, 45, 0.541);
  margin-bottom: 10px;
  padding: none;
  font-size: 1.5rem;
}
.explore p,
.support p {
  padding: 3px 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.explore p:hover,
.support p:hover {
  opacity: 0.5;
}

.call,
.email {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.call p,
.email p {
  margin-right: 7px;
}
.call button,
.email button {
  border: none;
  background-color: transparent;
  font-size: 0.95rem;
  cursor: pointer;
}
.call button:hover,
.email button:hover {
  opacity: 0.5;
}

.error {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #ff7474;
  background-color: #f2d7d7;
  color: #ff7474;
  display: none;
}

.thank-you {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #2d2d2d;
  background-color: #def2d7;
  color: #2d2d2d;
}

.help {
  margin-top: 30px;
}

#help {
  color: rgba(45, 45, 45, 0.541);
  margin-bottom: 10px;
  padding: none;
  font-size: 1.5rem;
}

.logo {
  height: 40px;
}

@media screen and (min-width: 805px) and (max-width: 999px) {
  .footer-links {
    display: flex;
    justify-content: space-around;
  }
  .help {
    margin-top: 30px;
  }
}
@media screen and (min-width: 1000px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0 100px 0;
  }
  .footer-links {
    display: flex;
    margin: 0 30px;
  }
  .help, .support, .explore {
    margin: 0 30px;
  }
}
.blog-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(95vh - 40px - 364px);
}

.return-container {
  margin-inline: auto;
  width: min(90%, 40rem);
}
.return-container div:first-of-type {
  text-align: center;
  margin: 50px 0;
}
.return-container h2 {
  font-size: 2rem;
}
.return-container p {
  line-height: 200%;
  margin-bottom: 50px;
}

.shipping-policy-container {
  margin-inline: auto;
  width: min(90%, 40rem);
}
.shipping-policy-container div:first-of-type {
  text-align: center;
  margin: 50px 0;
}
.shipping-policy-container h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}
.shipping-policy-container p {
  line-height: 200%;
  margin-bottom: 50px;
}

.contact-container {
  margin: 30px 0;
  text-align: center;
  min-height: 55vh;
  min-height: calc(95vh - 40px - 364px);
}
.contact-container h2 {
  font-size: 2rem;
}

.contact-inputs {
  margin-inline: auto;
  width: min(90%, 80rem);
}
.contact-inputs form {
  display: flex;
  flex-direction: column;
}
.contact-inputs input {
  width: calc(100% - 20px);
  height: 30px;
  padding: 10px 10px;
  border-radius: 40px;
  border: solid rgba(133, 133, 133, 0.438) 2px;
}
.contact-inputs input:nth-of-type(1) {
  margin-bottom: 15px;
  margin-top: 50px;
}
.contact-inputs input:nth-of-type(2) {
  margin-bottom: 15px;
}
.contact-inputs input:nth-of-type(3) {
  margin-bottom: 15px;
}
.contact-inputs input:nth-of-type(4) {
  margin-bottom: 15px;
}
.contact-inputs input:nth-of-type(5) {
  margin-bottom: 15px;
}
.contact-inputs input:focus {
  border: 2px solid #92a9bd;
}
.contact-inputs textarea {
  width: calc(100% - 20px);
  padding: 10px 10px;
  border-radius: 20px;
  border: solid rgba(133, 133, 133, 0.438) 2px;
  margin-bottom: 15px;
  outline: none;
}
.contact-inputs textarea:focus {
  border: 2px solid #92a9bd;
}
.contact-inputs button {
  padding: 10px;
  background-color: #92a9bd;
  border-radius: 30px;
  border: 1px solid transparent;
  color: white;
  font-size: 1.2rem;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.contact-inputs button:hover {
  color: #92a9bd;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.291);
}

@media screen and (min-width: 1000px) {
  .contact-container {
    margin-inline: auto;
    width: min(90%, 50rem);
  }
}
.checkout-container {
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  margin-inline: auto;
  width: min(90%, 60rem);
}

.checkout-information {
  margin: 30px 0;
  border-bottom: 1px solid var(--border);
}

.logo-checkout {
  margin-bottom: 20px;
}
.logo-checkout h2 {
  font-size: 3rem;
}
.logo-checkout img {
  height: 80px;
}

.checkout-cart-items {
  transition: height 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
  margin-top: 20px;
  overflow: hidden;
}

.cart-item-checkout {
  display: flex;
  align-items: center;
}
.cart-item-checkout img {
  height: 70px;
  border-radius: 8px;
  border: 1px solid black;
}

.cart-item-info {
  display: flex;
  align-items: center;
}
.cart-item-info div {
  margin-left: 10px;
}

.coupon-code {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: space-between;
}
.coupon-code input {
  transition: all 0.2s ease-in;
  border-radius: 10px;
  height: 40px;
  width: calc(100% - 100px);
  border: 1px solid rgba(0, 0, 0, 0.291);
  padding: 0 5px;
}
.coupon-code input:focus {
  outline: 1px solid black;
}
.coupon-code button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 60px;
  background-color: rgb(51, 51, 51);
  border-radius: 10px;
  border: none;
}
.coupon-code img {
  height: 35px;
}

.checkout-subtotal,
.checkout-shipping,
.checkout-tax,
.checkout-total,
.currency,
.cart-item-checkout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.checkout-subtotal {
  margin: 25px 0 10px 0;
}

.checkout-subtotal-container {
  margin: 20px 0;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.291);
  border-top: 1px solid rgba(0, 0, 0, 0.291);
}

.express {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.291);
  text-align: center;
  margin: 20px 0;
  border-radius: 10px;
}
.express p {
  padding-bottom: 10px;
}

.payment-buttons {
  display: flex;
  flex-direction: column;
}
.payment-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 5px 0;
  border-radius: 10px;
}
.payment-buttons button:nth-of-type(1) {
  background-color: rgb(250, 214, 118);
  border: 1px solid transparent;
}
.payment-buttons button:nth-of-type(2) {
  background-color: rgb(255, 196, 57);
  border: 1px solid transparent;
}
.payment-buttons button:nth-of-type(3) {
  background-color: white;
  border: black 1px solid;
}
.payment-buttons button img {
  height: 30px;
}

.contact-info {
  margin-bottom: 20px;
}
.contact-info h3 {
  font-size: 1.5rem;
}
.contact-info button {
  border: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.397);
}
.contact-info a {
  color: rgba(0, 0, 0, 0.411);
  transition: color 0.3s ease-in-out;
}
.contact-info a:hover {
  color: black;
}

.divider {
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divider div {
  background-color: rgba(0, 0, 0, 0.291);
  height: 1px;
  flex: 1;
}
.divider p {
  margin: 0 7px;
  color: rgba(0, 0, 0, 0.291);
}

.checkout-email {
  display: flex;
  flex-direction: column;
}
.checkout-email input {
  transition: all 0.2s ease-in;
  border-radius: 10px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.291);
  padding: 0 5px;
}
.checkout-email input:focus {
  outline: 1px solid black;
}

.checkbox {
  display: flex;
  align-items: center;
}
.checkbox p {
  margin-left: 5px;
}
.checkbox input:focus {
  outline: none;
}

.checkout-shipping-info {
  display: flex;
  flex-direction: column;
}
.checkout-shipping-info h2 {
  margin-bottom: 20px;
}
.checkout-shipping-info input {
  transition: all 0.2s ease-in;
  border-radius: 10px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.291);
  padding: 0 5px;
  background-color: transparent;
  margin-bottom: 10px;
}
.checkout-shipping-info input:focus {
  outline: 1px solid black;
}

.card-element-container {
  border: 1px solid var(--border);
  padding: 20px;
  margin-top: 10px;
}

.name,
.address {
  display: flex;
  flex-direction: column;
}

.offers-text {
  display: flex;
  align-items: center;
}
.offers-text p {
  margin-left: 5px;
}
.offers-text input {
  margin: 0;
}

.continue {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.continue button {
  padding: 10px;
  width: auto;
  margin: 10px 0;
  background-color: #92a9bd;
  border: 1px solid transparent;
  border-radius: 10px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.continue button:disabled {
  color: white;
  background-color: rgba(123, 141, 157, 0.4470588235);
}
.continue p {
  cursor: pointer;
}

.show-order {
  display: flex;
  align-items: center;
}
.show-order p {
  margin: 0 5px;
}
.show-order img {
  transform: rotate(90deg);
  height: 30px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.login-container {
  margin-inline: auto;
  width: min(90%, 30rem);
  text-align: center;
  margin-bottom: 100px;
  margin-top: 100px;
  min-height: calc(95vh - 40px - 364px);
}
.login-container button {
  margin-bottom: 10px;
}

.login-text p {
  margin-top: 10px;
}
.login-text h2 {
  font-size: 4rem;
}

.new-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(99, 99, 99, 0.815);
  margin-top: 20px;
}
.new-customer p:last-of-type {
  margin-right: 10px;
  position: relative;
  text-decoration: none;
  margin-left: 10px;
  color: #92a9bd;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.new-customer p:last-of-type:hover {
  color: black;
}
.new-customer p::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: #92a9bd;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.new-customer p:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.google {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  width: min(90%, 80rem);
}
.google img {
  height: 30px;
  margin-left: 5px;
}
.google button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid #92a9bd;
  color: black;
  font-size: 1.2rem;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.google button:hover {
  border: 1px solid black;
}

.account-container {
  margin: 50px 0;
  min-height: calc(95vh - 40px - 364px);
}

.greeting {
  font-size: 1.5rem;
}

.account-navigation {
  display: flex;
  justify-content: center;
  text-decoration: none;
  list-style: none;
  text-align: center;
}
.account-navigation li {
  padding: 0 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.account-navigation li:hover {
  color: var(--primary);
}

.account-orders {
  margin: 30px 0;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.account-orders h2 {
  font-size: 2rem;
}

.order-container {
  width: 150px;
  position: relative;
}
.order-container p {
  font-size: 1.3rem;
  margin-top: 100px;
  color: rgb(167, 167, 167);
}

.order-notification {
  position: absolute;
  border-radius: 10px;
  background-color: var(--off-black);
  color: white;
  width: 20px;
  height: 20px;
  right: 1%;
}

.order-components-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.orders {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orders p:nth-of-type(1) {
  margin-right: 10px;
}

.order-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.date,
.payment,
.city,
.state,
.zip,
.total {
  width: 80px;
}
.date p:first-of-type,
.payment p:first-of-type,
.city p:first-of-type,
.state p:first-of-type,
.zip p:first-of-type,
.total p:first-of-type {
  font-size: 0.7rem;
  color: rgba(99, 99, 99, 0.815);
}

.address {
  width: auto;
}
.address p:first-of-type {
  font-size: 0.7rem;
  color: rgba(99, 99, 99, 0.815);
}

.order-button button {
  height: 40px;
  width: 200px;
}
.order-button p {
  font-size: 1rem;
}

.orders-components-mobile {
  width: 200px;
  padding: 20px;
  border: 1px solid var(--border);
  border-radius: 10px;
}

.orders-wrapper {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-template-rows: repeat(auto, 300px);
  gap: 60px;
  margin-inline: auto;
  width: min(90%, 60rem);
  margin-top: 10px;
}

.addresses-wrapper {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-template-rows: repeat(auto, 200px);
  gap: 60px;
  margin-inline: auto;
  width: min(90%, 50rem);
  margin-top: 10px;
}

.add-address {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.add-address:hover {
  color: var(--primary);
}

.address-details-container {
  width: 200px;
  padding: 20px;
  border: 1px solid var(--border);
  border-radius: 10px;
}

.address-details p {
  padding: 5px 0;
}

.crud button {
  background-color: var(--primary);
  color: white;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  width: 70px;
  transition: all 0.3s ease-in-out;
}
.crud button:nth-of-type(2) {
  margin-left: 5px;
}
.crud button:hover {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.329);
  color: black;
}

.shipping-info,
.shipping-method {
  border: 1px solid var(--border);
  margin: 20px 0;
}

.shipping-email,
.shipping-address,
.payment-shipping-method {
  padding: 10px;
}
.shipping-email p:first-of-type,
.shipping-address p:first-of-type,
.payment-shipping-method p:first-of-type {
  color: rgb(109, 109, 109);
}

.shipping-email {
  border-bottom: 1px solid var(--border);
}

.payment-shipping-method {
  border-top: 1px solid var(--border);
}

.shipping-checkbox {
  display: flex;
  align-items: center;
}
.shipping-checkbox p {
  padding: 10px;
}
.shipping-checkbox img {
  height: 40px;
}

.shipping-method-selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid var(--border);
}
.shipping-method-selection input {
  border: none;
}

.shipping-method-selection:last-of-type {
  border: none;
}

.payment-payment {
  margin: 15px 0;
}

.card-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 850px) {
  .info-components {
    margin-top: 10px;
  }
  .checkout-container {
    margin-inline: auto;
    width: min(90%, 40rem);
  }
}
.add-address-container {
  display: flex;
  justify-content: flex-end;
  transition: visibility 0.5s ease-in-out;
}

.add-address-body {
  background-color: white;
  height: 100vh;
  width: 350px;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
}

.add-address p {
  cursor: pointer;
}

.address-form {
  padding: 20px;
}
.address-form form {
  display: flex;
  flex-direction: column;
}
.address-form form p {
  margin: 20px 0;
}
.address-form input {
  transition: all 0.2s ease-in;
  border-radius: 25px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.291);
  padding: 0 5px;
  background-color: transparent;
}
.address-form input:focus {
  outline: 1px solid black;
}
.address-form button {
  background-color: black;
  color: white;
  margin-top: 10px;
  border: none;
  border-radius: 25px;
  height: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 30px;
}
.address-form button:hover {
  color: black;
  border: 1px solid black;
  background-color: white;
}

.address-name {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.address-name input {
  width: 100%;
}
.address-name input:nth-of-type(1) {
  margin-right: 10px;
}

.address-phone {
  display: flex;
  flex-direction: column;
}
.address-phone input:nth-of-type(1) {
  margin-bottom: 20px;
}
.address-phone input:nth-of-type(2) {
  margin-bottom: 20px;
}

@media screen and (min-width: 1000px) {
  .add-address-body {
    width: 550px;
  }
}
.complete-container {
  margin-inline: auto;
  width: min(90%, 60rem);
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.complete-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
  width: 200px;
  margin: 10px 0;
  background-color: #92a9bd;
  border: 1px solid transparent;
  border-radius: 20px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.complete-container button img {
  height: 25px;
}
.complete-container p:nth-of-type(1) {
  margin-top: 30px;
}
.complete-container p:last-of-type {
  margin-top: 10px;
}

.shadow {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  border: 1px solid var(--border);
  border-radius: 10px;
}

.complete-text {
  margin: 20px 0;
}

.banner-container {
  height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: url("../components/Images/About/about.jpg") rgba(0, 0, 0, 0.175);
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  color: white;
  -webkit-animation: fade ease-in-out 1s;
          animation: fade ease-in-out 1s;
}
.banner-container a {
  color: white;
  font-size: 1.3rem;
  cursor: pointer;
}
.banner-container img {
  transform: rotate(180deg);
  height: 30px;
}

.home-logo {
  margin-bottom: 80px;
  -webkit-animation: slideUp ease-in-out 1s;
          animation: slideUp ease-in-out 1s;
}
.home-logo h1 {
  font-size: 5rem;
}

.banner-text {
  text-align: center;
  font-size: 1.5rem;
}

.home-collection {
  -webkit-animation: slideUp ease-in-out 1s;
          animation: slideUp ease-in-out 1s;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
}
.home-collection p {
  font-size: 2rem;
}

.home-shop {
  display: flex;
  align-items: center;
  background-color: rgb(64, 64, 64);
  border-radius: 10px;
  padding: 10px 30px;
  margin-top: 10px;
}

.banner-content {
  text-align: center;
  padding: 30px;
  border-radius: 10px;
}

.sellers-container {
  margin-top: 50px;
  margin-inline: auto;
  width: min(90%, 80rem);
  opacity: 0;
}
.sellers-container h1 {
  text-align: center;
}

.sellers {
  display: flex;
  align-items: center;
  overflow: scroll;
  overflow-y: hidden;
  margin: 20px 0;
}
.sellers h1 {
  text-align: center;
  margin-bottom: 20px;
}

.best-sellers-container {
  display: flex;
  position: relative;
}

.best-seller-carousel-button {
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: none;
  transition: opacity 0.2s ease-in-out;
  margin: 0 30px;
  cursor: pointer;
}

.carousel-controller {
  display: flex;
  align-items: center;
  justify-content: center;
}

.best-sellers {
  margin: 0 15px;
}
.best-sellers img {
  height: auto;
  width: 200px;
  border-radius: 10px;
}
.best-sellers p {
  font-size: 0.9rem;
}

.magazines {
  display: flex;
  align-items: center;
  justify-content: center;
}
.magazines img {
  width: 20%;
  padding: 0 5px;
}

.home-cover-container {
  margin: 70px 0;
  opacity: 0;
}

.home-cover-images {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-cover-images img {
  height: auto;
  width: 49%;
}

.home-cover-text {
  margin-top: 20px;
  text-align: center;
  font-size: 1.4rem;
}
.home-cover-text span {
  font-style: italic;
}
.home-cover-text button {
  margin-top: 20px;
  padding: 10px;
  background-color: #92a9bd;
  border-radius: 30px;
  border: 1px solid transparent;
  color: white;
  font-size: 1.1rem;
  height: 45px;
  width: 130px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.home-cover-text button:hover {
  color: #92a9bd;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.291);
}

@media screen and (min-width: 750px) {
  .best-sellers img {
    width: 400px;
  }
  .home-cover-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .home-cover-text {
    margin-top: 0px;
    padding: 50px;
    font-size: 1.8rem;
  }
  .home-cover-images {
    width: 65%;
  }
  .home-logo h1 {
    font-size: 9rem;
  }
  .home-logo p {
    font-style: italic;
    font-size: 2rem;
  }
  .home-shop {
    width: 130px;
    display: flex;
    justify-content: center;
  }
  .home-shop a {
    font-size: 1.5rem;
  }
  .sellers-container h1 {
    font-size: 3rem;
  }
  .home-cover-container:nth-last-of-type(1) {
    flex-direction: row-reverse;
  }
  .magazines {
    margin: 100px 0;
  }
}
.animation {
  -webkit-animation: slideUp forwards ease-in-out 1s;
          animation: slideUp forwards ease-in-out 1s;
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}
.spinner .path {
  stroke: hsl(210deg, 70%, 75%);
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}

.spinner-container {
  height: 100vh;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}/*# sourceMappingURL=App.css.map */