.contact-container {
  margin: 30px 0;
  text-align: center;
  h2 {
    font-size: 2rem;
  }
  min-height: 55vh;
  min-height: calc(95vh - 40px - 364px);
}

.contact-inputs {
  margin-inline: auto;
  width: min(90%, 80rem);
  form {
    display: flex;
    flex-direction: column;
  }
  input {
    width: calc(100% - 20px);
    height: 30px;
    padding: 10px 10px;
    border-radius: 40px;
    border: solid rgba(133, 133, 133, 0.438) 2px;
    &:nth-of-type(1) {
      margin-bottom: 15px;
      margin-top: 50px;
    }
    &:nth-of-type(2) {
      margin-bottom: 15px;
    }
    &:nth-of-type(3) {
      margin-bottom: 15px;
    }
    &:nth-of-type(4) {
      margin-bottom: 15px;
    }
    &:nth-of-type(5) {
      margin-bottom: 15px;
    }
    &:focus {
      border: 2px solid #92a9bd;
    }
  }
  textarea {
    width: calc(100% - 20px);
    padding: 10px 10px;
    border-radius: 20px;
    border: solid rgba(133, 133, 133, 0.438) 2px;
    margin-bottom: 15px;
    outline: none;
    &:focus {
      border: 2px solid #92a9bd;
    }
  }
  button {
    padding: 10px;
    background-color: #92a9bd;
    border-radius: 30px;
    border: 1px solid transparent;
    color: white;
    font-size: 1.2rem;
    height: 50px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #92a9bd;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.291);
    }
  }
}

@media screen and (min-width: 1000px) {
  .contact-container {
    margin-inline: auto;
    width: min(90%, 50rem);
  }
}
