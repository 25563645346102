@mixin column {
  display: flex;
  flex-direction: column;
}

.checkout-container {
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  margin-inline: auto;
  width: min(90%, 60rem);
}

.checkout-information {
  margin: 30px 0;
  border-bottom: 1px solid var(--border);
}

.logo-checkout {
  margin-bottom: 20px;
  h2 {
    font-size: 3rem;
  }
  img {
    height: 80px;
  }
}

.checkout-cart-items {
  transition: height 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
  margin-top: 20px;
  overflow: hidden;
}

.cart-item-checkout {
  @include flex();
  img {
    height: 70px;
    border-radius: 8px;
    border: 1px solid black;
  }
}

.cart-item-info {
  @include flex();
  div {
    margin-left: 10px;
  }
}

.coupon-code {
  @include flex();
  align-items: center;
  justify-content: space-between;
  input {
    transition: all 0.2s ease-in;
    border-radius: 10px;
    height: 40px;
    width: calc(100% - 100px);
    border: 1px solid rgba(0, 0, 0, 0.291);
    padding: 0 5px;
    &:focus {
      outline: 1px solid black;
    }
  }
  button {
    @include flex();
    justify-content: center;
    height: 40px;
    width: 60px;
    background-color: rgb(51, 51, 51);
    border-radius: 10px;
    border: none;
  }
  img {
    height: 35px;
  }
}

.checkout-subtotal,
.checkout-shipping,
.checkout-tax,
.checkout-total,
.currency,
.cart-item-checkout {
  @include flex();
  justify-content: space-between;
  margin: 10px 0;
}

.checkout-subtotal {
  margin: 25px 0 10px 0;
}

.checkout-subtotal-container {
  margin: 20px 0;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.291);
  border-top: 1px solid rgba(0, 0, 0, 0.291);
}

.express {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.291);
  text-align: center;
  margin: 20px 0;
  border-radius: 10px;
  p {
    padding-bottom: 10px;
  }
}

.payment-buttons {
  display: flex;
  flex-direction: column;
  button {
    @include flex();
    justify-content: center;
    height: 50px;
    margin: 5px 0;
    border-radius: 10px;
    &:nth-of-type(1) {
      background-color: rgba(250, 214, 118, 255);
      border: 1px solid transparent;
    }
    &:nth-of-type(2) {
      background-color: rgba(255, 196, 57, 255);
      border: 1px solid transparent;
    }
    &:nth-of-type(3) {
      background-color: white;
      border: black 1px solid;
    }
    img {
      height: 30px;
    }
  }
}

.contact-info {
  h3 {
    font-size: 1.5rem;
  }
  margin-bottom: 20px;
  button {
    border: none;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.397);
  }
  a {
    color: rgba(0, 0, 0, 0.411);
    transition: color 0.3s ease-in-out;
    &:hover {
      color: black;
    }
  }
}

.divider {
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    background-color: rgba(0, 0, 0, 0.291);
    height: 1px;
    flex: 1;
  }
  p {
    margin: 0 7px;
    color: rgba(0, 0, 0, 0.291);
  }
}

.checkout-email {
  @include column();
  input {
    transition: all 0.2s ease-in;
    border-radius: 10px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.291);
    padding: 0 5px;
    &:focus {
      outline: 1px solid black;
    }
  }
}

.checkbox {
  @include flex();
  p {
    margin-left: 5px;
  }
  input {
    &:focus {
      outline: none;
    }
  }
}

.checkout-shipping-info {
  @include column();
  h2 {
    margin-bottom: 20px;
  }
  input {
    transition: all 0.2s ease-in;
    border-radius: 10px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.291);
    padding: 0 5px;
    background-color: transparent;
    margin-bottom: 10px;
    &:focus {
      outline: 1px solid black;
    }
  }
}

.card-element-container {
  border: 1px solid var(--border);
  padding: 20px;
  margin-top: 10px;
}

.name,
.address {
  @include column();
}

.offers-text {
  @include flex();
  p {
    margin-left: 5px;
  }
  input {
    margin: 0;
  }
}

.continue {
  @include column();
  text-align: center;
  button {
    padding: 10px;
    width: auto;
    margin: 10px 0;
    background-color: #92a9bd;
    border: 1px solid transparent;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:disabled {
      color: white;
      background-color: #7b8d9d72;
    }
  }
  p {
    cursor: pointer;
  }
}

.show-order {
  @include flex();
  p {
    margin: 0 5px;
  }
  img {
    transform: rotate(90deg);
    height: 30px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
}
