.side-bar-nav {
  display: flex;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.908);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  transition: all ease-in .3s;
  li {
    padding: 10px 0;
    font-size: 1.5rem;
  }
  a {
    color: black;
  }
}

.close {
  position: absolute;
  top: 4.2%;
  right: 10.5%;
  font-size: 1.5rem;
  cursor: pointer;
}
