.test-cart {
  display: flex;
  justify-content: flex-end;
  transition: visibility 0.5s ease-in-out;
}

.cart-body {
  background-color: white;
  height: 100vh;
  width: 350px;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
}

.overlay {
  width: 100vw;
  height: 300vh;
  background-color: rgba(0, 0, 0, 0.435);
  position: absolute;
  z-index: 99;
  transition: opacity 0.5s, visibility 0s ease-in-out;
}

.cart-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.291);
  button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
  }
  p {
    margin-left: 10px;
  }
}

.bag {
  display: flex;
  align-items: center;
}

.shipping-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.border {
  border: 1px black solid;
  height: 7px;
  border-radius: 10px;
  margin-top: 10px;
}

.bar {
  background-color: black;
  height: 7px;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

// Cart Items

.item-full {
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: all 0.7s ease-in-out;
  position: relative;
  margin-top: 20px;
}

.item-container {
  padding: 10px;
}

.cart-item-container {
  @include flex();
}

.cart-item-container-text {
  width: 150px;
}

.cart-image {
  img {
    max-width: 80px;
    height: auto;
    border-radius: 10px;
    margin-right: 15px;
  }
  display: flex;
  align-items: center;
}

.add-sub {
  border: 1px solid black;
  input {
    border: none;
    width: 10px;
    &:focus {
      outline: none;
    }
  }
}

.quantity {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 50px);
  button {
    width: 30px;
    height: 30px;
    background-color: #e1e8ee;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: auto;
    height: 60%;
  }
}
.quantity input {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 32px;
  font-size: 16px;
  color: #43484d;
  font-weight: 300;
}

button:focus,
input:focus {
  outline: 0;
}

.subtotal {
  display: flex;
  justify-content: space-between;
}

.checkout {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.291);
  bottom: 0;
  button {
    background-color: black;
    color: white;
    margin-top: 10px;
    border: none;
    border-radius: 20px;
    height: 50px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:disabled {
      background-color: rgba(166, 166, 166, 0.63);
      color: rgb(255, 255, 255);
    }
  }
}

.cart-empty {
  margin-top: 50px;
}

.test {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cart-components {
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

@media screen and (min-width: 1000px) {
  .cart-body {
    width: 550px;
  }
  .cart-item-container-text {
    width: 200px;
  }
  .quantity {
    width: calc(100% - 100px);
  }
}
