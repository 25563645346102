.login-container {
  margin-inline: auto;
  width: min(90%, 30rem);
  text-align: center;
  margin-bottom: 100px;
  margin-top: 100px;
  button {
    margin-bottom: 10px;
  }
  min-height: calc(95vh - 40px - 364px);
}

.login-text {
  p {
    margin-top: 10px;
  }
  h2 {
    font-size: 4rem;
  }
}

.new-customer {
  @include flex();
  justify-content: center;
  color: rgba(99, 99, 99, 0.815);
  margin-top: 20px;
  p:last-of-type {
    margin-right: 10px;
    position: relative;
    text-decoration: none;
    margin-left: 10px;
    color: #92a9bd;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: black;
    }
  }

  p::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: #92a9bd;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  p:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
}

.google {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  width: min(90%, 80rem);
  img {
    height: 30px;
    margin-left: 5px;
  }
  button {
    @include flex();
    justify-content: center;
    padding: 10px;
    background-color: transparent;
    border-radius: 30px;
    border: 1px solid #92a9bd;
    color: black;
    font-size: 1.2rem;
    height: 50px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      border: 1px solid black;
    }
  }
}
