.footer-container-2 {
  background-color: #e3ebf4;
  border-top: 1px solid rgba(0, 0, 0, 0.24);
}

.footer-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-inline: auto;
  width: min(90%, 80rem);
  h2 {
    font-size: 2rem;
  }
  background-color: #e3ebf4;
}

.subscriber-input {
  position: relative;
}

.subscriber {
  width: 300px;
  p {
    padding: 10px 0;
  }
  input {
    width: calc(100% - 20px);
    height: 25px;
    padding: 10px 10px;
    border-radius: 25px;
    border: solid rgb(133, 133, 133) 1px;
    margin-top: 20px
  }
  button {
    @include flex();
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0;
    top: 45%;
    cursor: pointer;
  }
}

.explore,
.support {
  margin-top: 30px;
  p:first-of-type {
    color: rgba(45, 45, 45, 0.541);
    margin-bottom: 10px;
    padding: none;
    font-size: 1.5rem;
  }
  p {
    padding: 3px 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.5;
    }
  }
}

.call,
.email {
  @include flex();
  p {
    margin-right: 7px;
  }
  margin-bottom: 3px;
  button {
    border: none;
    background-color: transparent;
    font-size: 0.95rem;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}

.error {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #ff7474;
  background-color: #f2d7d7;
  color: #ff7474;
  display: none;
}

.thank-you {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #2d2d2d;
  background-color: #def2d7;
  color: #2d2d2d;
}

.help {
  margin-top: 30px;
}

#help {
  color: rgba(45, 45, 45, 0.541);
  margin-bottom: 10px;
  padding: none;
  font-size: 1.5rem;
}

.logo {
  height: 40px;
}

@media screen and (min-width: 805px) and (max-width: 999px) {
  .footer-links {
    display: flex;
    justify-content: space-around;
  }
  .help {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1000px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0 100px 0;
  }
  .footer-links {
    display: flex;
    margin: 0 30px;
  }
  .help, .support, .explore {
    margin: 0 30px;
  }
  
}
