:root {
  --primary: #92a9bd;
  --border: rgba(0, 0, 0, 0.24);
  --off-black: rgb(51, 51, 51);
}

html {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Albert Sans", sans-serif;
}

@import "./header";
@import "./product";
@import "./colors";
@import "./sidebar";
@import "./about";
@import "./cart";
@import "./slides";
@import "./footer";
@import "./blog";
@import "./return";
@import "./shipping";
@import "./contact";
@import "./checkout";
@import "./login";
@import "./account";
@import "./checkoutshipping";
@import "./addaddress";
@import "./thankyou";
@import "./home";
@import "./spinner";
