.complete-container {
  margin-inline: auto;
  width: min(90%, 60rem);
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  button {
    @include flex();
    justify-content: center;
    padding: 10px;
    text-align: center;
    width: 200px;
    margin: 10px 0;
    background-color: #92a9bd;
    border: 1px solid transparent;
    border-radius: 20px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    img {
      height: 25px;
    }
  }
  p:nth-of-type(1) {
    margin-top: 30px;
  }
  p:last-of-type {
    margin-top: 10px;
  }
}

.shadow {
  @include flex();
  flex-direction: column;
  padding: 50px;
  border: 1px solid var(--border);
  border-radius: 10px;
}

.complete-text {
  margin: 20px 0;
}
