.banner-container {
  height: 70vh;
  @include flex();
  flex-direction: column;
  justify-content: center;
  background: url("../components/Images/About/about.jpg") rgba(0, 0, 0, 0.175);
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  color: white;
  animation: fade ease-in-out 1s;
  a {
    color: white;
    font-size: 1.3rem;
    cursor: pointer;
  }
  img {
    transform: rotate(180deg);
    height: 30px;
  }
}

.home-logo {
  margin-bottom: 80px;
  animation: slideUp ease-in-out 1s;
  h1 {
    font-size: 5rem;
  }
}

.banner-text {
  text-align: center;
  font-size: 1.5rem;
}

.home-collection {
  animation: slideUp ease-in-out 1s;
  margin-top: 30px;
  @include flex();
  flex-direction: column;
  font-weight: bold;
  p {
    font-size: 2rem;
  }
}

.home-shop {
  @include flex();
  background-color: rgb(64, 64, 64);
  border-radius: 10px;
  padding: 10px 30px;
  margin-top: 10px;
}

.banner-content {
  text-align: center;
  padding: 30px;
  border-radius: 10px;
}

.sellers-container {
  margin-top: 50px;
  h1 {
    text-align: center;
  }
  margin-inline: auto;
  width: min(90%, 80rem);
  opacity: 0;
}

.sellers {
  @include flex();
  overflow: scroll;
  overflow-y: hidden;
  margin: 20px 0;
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
}

.best-sellers-container {
  display: flex;
  position: relative;
}

.best-seller-carousel-button {
  @include flex();
  background-color: white;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: none;
  transition: opacity 0.2s ease-in-out;
  margin: 0 30px;
  cursor: pointer;
}

.carousel-controller {
  @include flex();
  justify-content: center;
}

.best-sellers {
  margin: 0 15px;
  img {
    height: auto;
    width: 200px;
    border-radius: 10px;
  }
  p {
    font-size: 0.9rem;
  }
}

.magazines {
  @include flex();
  justify-content: center;
  img {
    width: 20%;
    padding: 0 5px;
  }
}

.home-cover-container {
  margin: 70px 0;
  opacity: 0;
}

.home-cover-images {
  @include flex();
  justify-content: space-between;
  img {
    height: auto;
    width: 49%;
  }
}

.home-cover-text {
  margin-top: 20px;
  text-align: center;
  font-size: 1.4rem;
  span {
    font-style: italic;
  }
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #92a9bd;
    border-radius: 30px;
    border: 1px solid transparent;
    color: white;
    font-size: 1.1rem;
    height: 45px;
    width: 130px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #92a9bd;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.291);
    }
  }
}

@media screen and (min-width: 750px) {
  .best-sellers {
    img {
      width: 400px;
    }
  }
  .home-cover-container {
    @include flex();
    justify-content: space-around;
  }
  .home-cover-text {
    margin-top: 0px;
    padding: 50px;
    font-size: 1.8rem;
  }
  .home-cover-images {
    width: 65%;
  }
  .home-logo {
    h1 {
      font-size: 9rem;
    }
    p {
      font-style: italic;
      font-size: 2rem;
    }
  }
  .home-shop {
    width: 130px;
    display: flex;
    justify-content: center;
    a {
      font-size: 1.5rem;
    }
  }
  .sellers-container {
    h1 {
      font-size: 3rem;
    }
  }
  .home-cover-container:nth-last-of-type(1) {
    flex-direction: row-reverse;
  }
  .magazines {
    margin: 100px 0;
  }
}

// Animations

.animation {
  animation: slideUp forwards ease-in-out 1s;
}
