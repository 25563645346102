@mixin flex {
  display: flex;
  align-items: center;
}

.logo {
  @include flex();
  a {
    text-decoration: none;
    color: black;
  }
  h2 {
    font-size: 3rem;
  }
  img {
    height: 80px;
  }
}

header {
  @include flex();
  justify-content: space-between;
  padding: 30px 30px;
  h2 {
    font-size: 1.7rem;
    cursor: pointer;
  }
}

.nav {
  display: none;
  cursor: pointer;
  position: relative;
}

.cart-num {
  @include flex();
  position: absolute;
  justify-content: center;
  border: 1px solid;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  font-size: 0.9rem;
  background-color: #92a9bd;
  color: white;
  bottom: 1%;
  right: 1%;
}

.banner {
  background-color: rgb(51, 51, 51);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  position: relative;
  cursor: pointer;
  p {
    animation: slideUpBanner 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  p:nth-of-type(2) {
    display: none;
    animation: slideUp 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  img:first-of-type {
    height: 25px;
    position: absolute;
    left: 1%;
  }
  img:last-of-type {
    position: absolute;
    height: 25px;
    right: 1%;
  }
}

@media screen and (min-width: 760px) {
  .nav {
    @include flex();
    font-size: 1.1rem;
  }
  li {
    padding: 0 10px;
  }
  a {
    position: relative;
    text-decoration: none;
    color: black;
  }

  a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: #92a9bd;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }

  .mobile-menu {
    display: none;
  }

  header {
    position: sticky;
    top: 0;
    background-color: white;
    h2 {
      font-size: 2rem;
    }
  }
  .banner {
    img:first-of-type {
      left: 20%;
    }
    img:last-of-type {
      right: 20%;
    }
  }
}

@media screen and (min-width: 1300px) {
  .banner {
    img:first-of-type {
      left: 30%;
    }
    img:last-of-type {
      right: 30%;
    }
  }
  .logo {
    margin: 0px;
    display: flex;
    align-items: center;
  }
}

@keyframes slideUpBanner {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
