.about-container {
  margin-inline: auto;
  width: min(90%, 80rem);
  margin-bottom: 100px;
}

.about-cover {
  position: relative;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    border: 1px black solid;
  }
}

.image-text {
  span::before {
    content: '';
    opacity: 0;
  }
}

.image-text {
  font-size: 8.5vw;
  text-align: center;
  position: absolute;
  top: 5%;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.404);
  span:first-of-type {
    animation: fade .5s ease-in-out;
    
  }
  span:nth-of-type(2) {
    animation: fade 1s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  span:nth-of-type(3) {
    animation: fade 1s ease-in-out;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  span:nth-of-type(4) {
    animation: fade 1s ease-in-out;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
}

.arrow {
  margin-top: 6px;
  cursor: pointer;
  animation: slideUp 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  &:hover {
    padding: 6px;
  }
}

.about-description {
  margin: 40px 0;
  p {
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: 160%;
    padding: 30px 20px;
    border-top: 1px rgba(0, 0, 0, 0.24) solid;
  }
  border-bottom: 1px rgba(0, 0, 0, 0.24) solid;
}

.our-promise {
  img {
    width: 100%;
    border: black 1px solid;
  }
  margin-top: 20px;
}

.contact {
  margin-bottom: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.291);
  border-top: 1px solid rgba(0, 0, 0, 0.291);

  p {
    padding: 5px 30px;
  }
}

.input-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  input {
    height: 20px;
    width: auto;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.291);
    padding: 8px;
  }
  input::placeholder {
    opacity: 0.2;
  }
  input:focus {
    border: black 1px solid;
    outline: none;
  }
}

.about-button {
  button {
    background-color: #92a9bd;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #92a9bd;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.291);
    }
  }
}

@media screen and (min-width: 1000px) {
  .about-container {
    width: min(90%, 50rem);
  }
  .image-text {
    font-size: 4rem;
    padding: 0 90px;
  }
  .about-description {
    p {
      padding: 50px 100px;
      line-height: 200%;
    }
  }
  .about-cover {
    img {
      height: auto;
      width: 85%;
    }
  }
  .contact {
    p {
      margin-top: 10px;
    }
  }
}





